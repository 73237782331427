import Link from 'next/link';

import { Text } from '@northladder/i18n';
import { Error404GhostAnimation } from '@northladder/design-system';

import { translations } from '../translations';
import { LoginLayout } from '../layouts';

/**
 * -----------------------------------------------------------------------------
 * This renders the fallback 404 when the user tries to visit a route that is not
 * present.
 */
export default function ErrorPage() {
  const tr = translations.fallbackPages.notFound;

  return (
    <LoginLayout title="404 | Page not found | Dealer @ NorthLadder">
      <div
        className="
          mt-8
          flex
          flex-col
          items-center
          justify-center
          p-4
          lg:-mt-24
        "
      >
        <div className="w-2/3 md:h-72 md:w-96">
          <Error404GhostAnimation />
        </div>
        <h1
          className="
            mt-12
            py-2
            text-xl
            font-normal
            leading-tight
            tracking-tight
            text-violet-800
            transition-all
            duration-500
            md:mt-2
            md:text-2xl
          "
        >
          <Text text={tr.title} />
        </h1>
        <p />
        <p className="max-w-sm py-2 text-center text-sm text-gray-800">
          <Text text={tr.message} />
        </p>
        <div className="mt-12 flex w-full justify-end">
          <Link className="form-btn xw-full mx-auto my-4 w-auto px-12" href="/">
            <Text text={tr.action} />
          </Link>
        </div>

        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          <Text text={tr.altActionText} />

          <a
            className="form-link-action ml-2"
            href="https://www.northladder.com/ae-en/contact-us"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Text text={tr.altActionLinkText} />
          </a>
        </p>
      </div>
    </LoginLayout>
  );
}
